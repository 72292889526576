import React, { useState } from "react";

const Modpage = ({ socket }) => {
    const [selectedTeam, setSelectedTeam] = useState("red");

    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const handleEmit = (value) => {
        // Emit the 'points' event with the selected team and the provided value
        socket.emit("points", { team: selectedTeam, value });
    };

    return (
        <div className="App" style={{ padding: '20px', textAlign: 'center' }}>
            {/* Dropdown Menu for Selecting Team */}
            <label htmlFor="team-select">Choose a team: </label>
            <select
                id="team-select"
                value={selectedTeam}
                onChange={handleTeamChange}
                style={{ marginBottom: '20px' }}
            >
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
            </select>

            {/* Buttons for Emitting Events */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px',
                    maxWidth: '300px',
                    margin: '0 auto',
                }}
            >
                <button onClick={() => handleEmit(4)}>Bingo Add</button>
                <button onClick={() => handleEmit(-3)}>Bingo Remove</button>
                <button onClick={() => handleEmit(3)}>Challenge First Add</button>
                <button onClick={() => handleEmit(-3)}>Challenge First Remove</button>
                <button onClick={() => handleEmit(1)}>Challenge After Add</button>
                <button onClick={() => handleEmit(-1)}>Challenge After Remove</button>
            </div>
        </div>
    );
};

export default Modpage;
