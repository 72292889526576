import React, { useEffect, useState } from 'react';

const VDOPlayer = ({ socket }) => {
    const [activeStreams, setActiveStreams] = useState([]);
    const [teamPoints, setTeamPoints] = useState({
        red: 0,
        blue: 0,
        green: 0,
        orange: 0,
        purple: 0,
    });
    const [showBorder, setShowBorder] = useState(false);

    useEffect(() => {
        // Listen for active streams from the server
        socket.on('activeStreams', (streams) => {
            // Sort the streams by stream.team each time new data is received
            const sortedStreams = [...streams].sort((a, b) => {
                if (a.team < b.team) return -1;
                if (a.team > b.team) return 1;
                return 0;
            });
            setActiveStreams(sortedStreams);
        });

        // Listen for team points updates from the server
        socket.on('update_team_points', (points) => {
            setTeamPoints(points);
        });

        return () => {
            // Clean up the socket listeners when the component unmounts
            socket.off('activeStreams');
            socket.off('update_team_points');
        };
    }, [socket]);

    const videoWidth = 315; // Adjust the individual video width
    const videoSpacing = 20; // Adjust the spacing between videos

    const viewerStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '20px',
    };

    return (
        <div style={viewerStyle} key={JSON.stringify(activeStreams)}>
            {activeStreams.map((stream) => (
                <div
                    key={stream.id}
                    className="Viewer"
                    style={{
                        position: 'relative',
                        border: showBorder ? `2px solid ${stream.team}` : 'none',
                        background: `${stream.team}`,
                        padding: '3px',
                        borderRadius: '10px',
                    }}
                >
                    {/* Team Name and Points */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: `${stream.team}`,
                        }}
                    >
                        <p
                            style={{
                                color: 'white',
                                fontWeight: 'bolder',
                                fontFamily: 'Roboto, sans-serif',
                                textShadow: '2px 2px 5px black',
                                fontSize: '25px',
                                margin: 0,
                            }}
                        >
                            {stream.name}
                        </p>
                        <div
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                fontFamily: 'Roboto, sans-serif',
                                textShadow: '2px 2px 5px black',
                                padding: '0 10px',
                                borderRadius: '5px',
                            }}
                        >
                            Punkte: {teamPoints[stream.team] || 0}
                        </div>
                    </div>

                    {/* Video feed */}
                    <div className="video-item" style={{ marginTop: '10px' }}>
                        <iframe
                            title={`Video Feed ${stream.name}`}
                            width={videoWidth}
                            height={(videoWidth * 9) / 16}
                            src={`${stream.link}&autoplay=1&muted=1`}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VDOPlayer;
