import React, { useEffect, useState } from "react";
import BingoTable from "../components/BingoTable";
import ShiftComponent from "../components/ShiftComponent";
import Countdown from "../components/Countdown";

const Admin = ({ socket }) => {
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [tableData, setTableData] = useState([{ name: "", vdoLink: "", team: "red" }]);
    const [timerRunning, setTimerRunning] = useState(false);
    const [timerTime, setTimerTime] = useState(0);

    useEffect(() => {
        // Fetch initial timer state from server when the component mounts
        socket.emit("get_timer_state");

        // Listen for timer state updates from the server
        socket.on("timer_state", (data) => {
            setTimerTime(data.time); // Assuming data.time contains the current time in seconds
            setTimerRunning(data.isRunning); // Assuming data.isRunning is a boolean
        });

        socket.on("receive_field", (data) => {
            alert(data.payload);
        });

        // Cleanup the socket listener when the component unmounts
        return () => {
            socket.off("timer_state");
            socket.off("receive_field");
        };
    }, [socket]);

    const [playerInfo] = useState({
        username: "None",
        team: "None",
    });

    const update = () => {
        socket.emit("refresh_board", { withCheckbox: isCheckboxChecked });
    };

    const shuffle = () => {
        socket.emit("shuffle_board");
    };

    const handleCheckboxChange = () => {
        setCheckboxChecked(!isCheckboxChecked);
    };

    const handleTableChange = (index, field, value) => {
        const newData = [...tableData];
        newData[index][field] = value;
        setTableData(newData);
    };

    const addRow = () => {
        setTableData([...tableData, { name: "", vdoLink: "", team: "red" }]);
    };

    const startStreams = () => {
        tableData.forEach((row) => {
            const { name, vdoLink, team } = row;
            if (name.trim() !== "" && vdoLink.trim() !== "") {
                socket.emit('startStream', { link: vdoLink, team, name });
            }
        });
    };

    const stopStreams = () => {
        tableData.forEach((row) => {
            socket.emit('closeStream');
        });
    };

    const startCountdown = () => {
        socket.emit('startCountdown');
    };

    const stopCountdown = () => {
        socket.emit('stopCountdown');
    };

    const resetCountdown = () => {
        socket.emit('resetCountdown', 14400); // Reset countdown on server
    };

    return (
        <div className="App">
            <input
                type="checkbox"
                id="includeCheckbox"
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
            />
            <button className="refresh" onClick={update}>
                Refresh Board
            </button>
            <button className="shuffle" onClick={shuffle}>
                Shuffle Board
            </button>
            <ShiftComponent socket={socket}/>
            <div className="row-container">
                <BingoTable socket={socket} playerInfo={playerInfo}/>
            </div>

            {/* Table for Name, VDO Link, Team */}
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>VDO Link</th>
                    <th>Team</th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <input
                                type="text"
                                value={row.name}
                                onChange={(e) => handleTableChange(index, "name", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={row.vdoLink}
                                onChange={(e) => handleTableChange(index, "vdoLink", e.target.value)}
                            />
                        </td>
                        <td>
                            <select
                                value={row.team}
                                onChange={(e) => handleTableChange(index, "team", e.target.value)}
                            >
                                <option value="red">Red</option>
                                <option value="green">Green</option>
                                <option value="blue">Blue</option>
                                <option value="orange">Orange</option>
                                <option value="purple">Purple</option>
                            </select>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button onClick={addRow}>Add Row</button>
            <button onClick={startStreams}>Start Streams</button>
            <button onClick={stopStreams}>Stop Streams</button>
            <Countdown socket={socket}/>
            <button onClick={startCountdown}>Start</button>
            <button onClick={stopCountdown}>Stop</button>
            <button onClick={resetCountdown}>Reset</button>
        </div>
    );
};

export default Admin;
