import React, { useEffect, useState } from 'react';

const Countdown = ({ socket }) => {
    const [time, setTime] = useState(14400); // Initial countdown time in seconds (4 hours)

    useEffect(() => {
        // Listen for countdown updates from the server
        socket.on('countdownUpdate', (newTime) => {
            setTime(newTime);
        });

        // Clean up the socket connection on component unmount
        return () => {
            socket.off('countdownUpdate');
        };
    }, [socket]);

    const formatTime = (totalSeconds) => {
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const seconds = String(totalSeconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    return (
        <div>
            <h1>{formatTime(time)}</h1>
        </div>
    );
};

export default Countdown;
